<template>
  <div>
    <NavigationBar titel="Support"></NavigationBar>
    <v-container class="my-5">
      <!-- <h1 class="text-h4 mb-4">Support</h1> -->
      <p class="subtitle-1 mb-6">
        Unser Support steht zu unseren Geschäftszeiten zur Verfügung und hilft
        bei Fragen oder Problemen, die bei der Verwendung von Mira One
        auftreten. Du erreichst ihn via E-Mail unter
        <a href="mailto:support@mira-one.com" class="text-decoration-none"
          >support@mira-one.com</a
        >
        oder über das Kontaktformular unten rechts.
      </p>
      <v-row>
        <v-col
          cols="12"
          sm="12"
          lg="6"
          v-for="(item, index) in items"
          :key="index"
        >
          <v-card>
            <v-card-title class="headline">{{ item.title }}</v-card-title>
            <v-card-subtitle style="margin: auto">{{
              item.subtitle
            }}</v-card-subtitle>
            <v-card-actions>
              <v-btn text :href="item.link" target="_blank">
                {{ item.btnText }}
                <v-icon right light>mdi-open-in-new</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";

export default {
  name: "support",
  components: {
    NavigationBar,
  },
  data() {
    return {
      items: [
        {
          title: "Wissensdatenbank",
          subtitle:
            "Bei der Verwendung, der Behebung von Problemen hilft die Wissensdatenbank in Form von Artikeln, Tutorials, FAQs und mehr.",
          link: "https://docs.mira-one.com",
          btnText: "Öffnen",
        },
        {
          title: "Systemstatus",
          subtitle:
            "Die Funktionsfähigkeit des Systems, Wartungsarbeiten, auftretende Störungen sowie Problembehebungen werden 24/7 in Echtzeit angezeigt.",
          link: "https://status.mira-one.com",
          btnText: "Öffnen",
        },
      ],
    };
  },
  methods: {},
};
</script>
